<template>
    <div>
        <pay-box title="分销佣金比例" sub-title="（根据微信官方政策，自动分账总比例须≤30%）">
            <table class="share-setting-table">
                <template v-for="(title,key) in shareAttributes">
                    <tr :key="key">
                        <td>{{ title }}</td>
                        <td>
                            <a-input v-model="result[key]" addonAfter="%"/>
                        </td>
                    </tr>
                </template>
            </table>
        </pay-box>
        
        <div class="flex flex-left-center">
            <button class="btn" @click="saveConfig">确认</button>
            <span class="share-setting-tips">*不填或填为“0”代表不进行分佣</span>
        </div>
    </div>
</template>
<script>
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import api from "@/repo/api";

export default {
    name: "DetailShareSetting",
    components: {PayBox},
    props: {
        memberId: String,
    },
    data() {
        return {
            shareAttributes: {},
            result: {},
        }
    },
    mounted() {
        this.fetchConfig()
    },
    methods: {
        fetchData() {
            api.get('/ast-member/order-share-setting', {am_id: this.memberId}).then(res => {
                this.result = res.data;
            })
        },
        fetchConfig() {
            api.get('/ast-member/order-share-config').then(res => {
                this.shareAttributes = res.data;
                this.fetchData()
            })
        },
        saveConfig() {
            this.$loading.show()
            
            this.result.am_id = this.memberId
            
            api.post('/ast-member/change-order-share-setting', this.result).then(res => {
                this.$loading.hide()
                this.$message.data(res)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.share-setting-tips {
    font-weight: 400;
    font-size: 16px;
    color: #FBB03B;
    line-height: 19px;
    text-align: center;
    margin-left: 12px;
}
</style>