<template>
    <div v-if="member">
        <div v-if=" showYcInfo " class="tj-group">
            <div class="group-title">颜创体系推荐</div>
            <div class="group-content tm-list">

                <div class="tm-card">
                    <template v-if="ycParents[1]">
                        <member-card :all="ycParents[1]"/>
                    </template>
                    <template v-else>本层级（空）</template>
                </div>
                <div class="tm-jt">
                    <div>推荐</div>
                </div>
                <div class="tm-card">
                    <template v-if="ycParents[0]">
                        <member-card :all="ycParents[0]"/>
                    </template>
                    <template v-else>本层级（空）</template>
                </div>
                <div class="tm-jt">
                    <div>推荐</div>
                </div>
                <div class="tm-card">
                    <member-card self :ast="member"/>
                </div>

            </div>
        </div>
        <div v-if="showYcInfo" class="line"></div>
        <div class="tj-group" v-if="astParents">
            <div v-if="showYcInfo" class="group-title">本小程序体系</div>
            <div class="group-content tm-list">
                <div class="tm-card">
                    <template v-if="astParents[1]">
                        <member-card :ast="astParents[1]"/>
                    </template>
                    <template v-else>本层级（空）</template>
                </div>
                <div class="tm-jt">
                    <div>推荐</div>
                </div>
                <div class="tm-card">
                    <template v-if="astParents[0]">
                        <member-card :ast="astParents[0]"/>
                    </template>
                    <template v-else>本层级（空）</template>
                </div>
                <div class="tm-jt">
                    <div>推荐</div>
                </div>
                <div class="tm-card">
                    <member-card self :ast="member"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MemberCard from "@/views/AST/Cashier/Com/MemberCard.vue";

export default {
    name: "Recommend",
    components: {MemberCard},
    props: {
        member: Object,
        astParents: Array,
        ycParents: Array
    },
    computed:{
        showYcInfo(){
            return [15,9].indexOf(this.member.app_id) >= 0 && this.ycParents
        }
    }
}
</script>

<style scoped lang="less">
.tm-card {
    width: 200px;
    height: 144px;
    background: #F5F5F5;
    border-radius: 2px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
}

.tm-jt {
    width: 74px;
    height: 144px;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;

    color: #333333;
    font-weight: 400;
    line-height: 14px;

    div:after {
        display: block;
        content: ' ';
        width: 74px;
        height: 9px;
        background-image: url("/assets/icons/share-jiantou.png");
        background-size: cover;
    }
}


.tm-list {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.line {
    width: 100%;
    height: 1px;
    margin-top: 32px;
    margin-bottom: 16px;
    border-bottom: 1px solid #EEEEEE;
}

.group-title {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 14px;
    margin-bottom: 20px;
}
</style>